<template>
  <div class="ml-5 mr-5 margin-top mb-5">
    <div class="grid">
      <div class="flex order-form-title">
        Product Management<i class="chevron-right-large-icon"></i>{{ title }}
      </div>
      <div class="order-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-3">
              <div class="order-form-sub-title">Product Detail</div>
            </div>
            <div class="col-9">
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.productserialno"
                    :validation="v$.vmodel.productserialno"
                    sectionClass="field mt-1"
                    id="productserialno"
                    label="Serial No."
                    placeholder="Serial No."
                  />
                </div>
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.productname"
                    :validation="v$.vmodel.productname"
                    sectionClass="field mt-1"
                    id="productname"
                    label="Product Name"
                    placeholder="Product Name"
                  />
                </div>
              </div>
              <div>
                <label for="photo" class="form-label flex mb-2">Image</label>
                <FileUpload
                  ref="photoImage"
                  name="photo"
                  :multiple="true"
                  @select="onImageUpload($event)"
                  :aria-describedby="`photo-help`"
                >
                  <template #header>
                    <div
                      class="flex align-items-center justify-content-center flex-column"
                    >
                      <span class="upload-icon"></span>
                      <div class="upload-text">
                        Drag and Drop files here or
                        <PrimeButton
                          label="Browse"
                          class="browse-btn cursor-pointer"
                          @click="openImageUpload()"
                        ></PrimeButton>
                      </div>
                    </div>
                  </template>
                  <template #content>
                    <div v-if="imageList.length > 0" class="grid">
                      <div
                        v-for="(image, index) in imageList"
                        :key="index"
                        class="col-3"
                      >
                        <div class="img-card">
                          <img
                            role="presentation"
                            :alt="image.imagename"
                            :src="image.imagename"
                            class="img-content"
                          />
                          <div class="grid card-content">
                            <span class="col-10 font-semibold img-text">
                              <div class="grid m-0">
                                <span class="col-12 image-name">{{
                                  image.originalname
                                }}</span>
                                <span class="col-12 image-size"
                                  >{{
                                    parseFloat(image.size / 1048576).toFixed(4)
                                  }}
                                  MB</span
                                >
                              </div>
                            </span>
                            <div
                              class="col-2 more-btn"
                              :class="{
                                'image-selected': visibleImageDDMenu == index,
                                '': visibleImageDDMenu !== index,
                              }"
                            >
                              <i
                                class="more-icon cursor-pointer margin-arrow col-2 mt-2"
                                @click="toggleImageMenu($event, index)"
                                aria-haspopup="true"
                                :aria-controls="'overlay_menuimage-' + index"
                              ></i>
                            </div>
                            <Menu
                              class="menustyle"
                              :id="'overlay_menuimage-' + index"
                              :ref="'imageMenu-' + index"
                              :model="setImageMenuItems(image.imagename, index)"
                              :popup="true"
                              @hide="hideImageDD"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </FileUpload>
                <div
                  v-if="
                    v$.vmodel.images.$error &&
                    v$.vmodel.images.required &&
                    v$.vmodel.images.required.$invalid
                  "
                  :id="`photo-help`"
                  class="p-error text-left mt-2"
                >
                  {{
                    v$.vmodel.images.required.$message.replace("Value", "Image")
                  }}
                </div>
              </div>
              <div class="grid mt-2">
                <div class="col-12">
                  <TextareaRequire
                    v-model="vmodel.description"
                    :validation="v$.vmodel.description"
                    sectionClass="field mt-1"
                    id="description"
                    label="Description"
                    placeholder="Description"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <DropDownRequire
                    v-model="vmodel.assignedcustomer"
                    :validation="v$.vmodel.assignedcustomer"
                    :options="customerOptions"
                    sectionClass="field mt-1"
                    id="assignedcustomer"
                    label="Assign Customer"
                    placeholder="Assign Customer"
                    optionLabel="username"
                    optionValue="id"
                  />
                </div>
              </div>
              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="order-form-button"
                  v-if="!isEditMode"
                  @click="createProduct"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="order-form-button"
                  v-else
                  @click="createProduct"
                ></PrimeButton>
                <PrimeButton
                  label="Cancel"
                  class="form-cancel-button"
                  @click="onCancel"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import APIService from "@/services/api-service.js";
import { required, maxLength, minLength, numeric } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      visibleImageDDMenu: -1,
      photo: "",
      imageList: [],
      imgList: [],
      submitted: false,
      isEditMode: false,
      title: "Add",
      customerOptions: [],
      errorcatch: 0,
      imageFileTypes: ["png", "jpg", "jpeg"],
      vmodel: {
        productserialno: "",
        productname: "",
        images: [],
        description: "",
        assignedcustomer: "",
      },
    };
  },
  validations() {
    return {
      vmodel: {
        images: { required },
        productserialno: {
          required,
          numeric,
          max: maxLength(9),
          min: minLength(9),
        },
        productname: { required, max: maxLength(50) },
        description: { required, max: maxLength(50) },
        assignedcustomer: { required },
      },
    };
  },
  mounted() {
    this.getCustomers();
    if (this.$route.params.id) {
      this.title = "Edit";
      this.isEditMode = true;
      this.getProductById();
    }
  },
  methods: {
    hideImageDD() {
      this.visibleImageDDMenu = -1;
    },
    toggleImageMenu(event, index) {
      this.visibleImageDDMenu = index;
      this.$refs[`imageMenu-${index}`][0].toggle(event);
    },
    openImageUpload() {
      this.$refs.photoImage.choose();
    },
    onRemoveImageFile(index) {
      this.imgList.splice(index, 1);
      this.imageList.splice(index, 1);
      this.visibleImageDDMenu = -1;
    },
    onCancel() {
      this.$router.push({ name: "Product" });
    },
    setImageMenuItems(file, index) {
      let menu = [];
      if (file) {
        menu = [
          {
            label: "Delete",
            command: () => {
              this.onRemoveImageFile(index);
            },
          },
        ];
      }
      return menu;
    },
    onImageUpload(fileObj) {
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.errorcatch++;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid image",
          life: 3000,
        });
        this.$refs.photoImage.clear();
      } else if (fileObj && fileObj.files) {
        for (let file of fileObj.files) {
          if (file.size > 2000000) {
            this.errorcatch++;
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Image size should be less then 2 MB",
              life: 3000,
            });
            this.$refs.photoImage.clear();
          }

          let ext = /^.+\.([^.]+)$/.exec(file.name);
          if (!this.imageFileTypes.includes(ext[1].toLowerCase())) {
            this.errorcatch++;
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Image Type must be .jpg, .png or .jpeg",
              life: 3000,
            });
            this.$refs.photoImage.clear();
          }
        }
      }

      if (this.errorcatch == 0) {
        let formData = new FormData();
        for (let file of fileObj.files) {
          formData.append("image", file);
        }
        APIService.post("/product/product", formData, null, true)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              for (let file of response.data.data) {
                const uploadImgList = {
                  imagename:
                    process.env.VUE_APP_PRODUCT_IMAGE_BASE_PATH +
                    file.imagename,
                  originalname: file.originalname,
                  size: file.size,
                };
                const uploadedImg = {
                  imagename: file.imagename,
                  originalname: file.originalname,
                  size: file.size,
                };
                this.imgList.push(uploadedImg);
                this.imageList.push(uploadImgList);
              }
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$refs.photoImage.clear();
          });
      }
      this.errorcatch = 0;
    },
    async createProduct() {
      this.vmodel.images = this.imgList;
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          serialnumber: this.vmodel.productserialno,
          image: this.vmodel.images,
          name: this.vmodel.productname,
          description: this.vmodel.description,
          customerid: this.vmodel.assignedcustomer,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/product", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Product" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/product", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Product" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getProductById() {
      APIService.getById("/product", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.productserialno = response.data.data.serialnumber;
            this.vmodel.images = response.data.data.image;
            if (
              response.data.data.image &&
              response.data.data.image.length > 0
            ) {
              response.data.data.image.forEach((element) => {
                const uploadImgList = {
                  imagename:
                    process.env.VUE_APP_PRODUCT_IMAGE_BASE_PATH +
                    element.imagename,
                  originalname: element.originalname,
                  size: element.size,
                };
                const uploadedImg = {
                  imagename: element.imagename,
                  originalname: element.originalname,
                  size: element.size,
                };
                this.imageList.push(uploadImgList);
                this.imgList.push(uploadedImg);
              });
            }
            this.vmodel.productname = response.data.data.name;
            this.vmodel.description = response.data.data.description;
            this.vmodel.assignedcustomer = response.data.data.customerid;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Product" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    async getCustomers() {
      APIService.get("/customer/dropdown").then((response) => {
        if (response && response.data && response.data.data) {
          this.customerOptions = response.data.data;
        }
      });
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/upload-image.css";
</style>
