import DateFormat from "@/libs/DateFormat"

export default class Customer {
    constructor(data = {}) {
        return {
            id: data.id || '',
            userid: data.userid || '',
            avatar: data.avatar || 'avatar-default.png',
            username: data.username || '',
            phoneno: data.phoneno || '',
            email: data.email || '',
            address: data.address || '',
            createddate: DateFormat.formatDateTime(data.createddate) || '',
        }
    }
}