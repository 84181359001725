<template>
  <div class="main-container">
    <div class="login-form">
      <div class="form-container form-container-forgot">
        <div class="login-logo">
          <img src="../assets/icons/logo.png" />
        </div>
        <div class="flex">
          <!-- <span
            class="chevron-left-icon cursor-pointer mt-3 margin"
            @click="gotoLogin"
          ></span> -->
          <div class="form-title mt-3 margin">Forgot Password</div>
        </div>
        <div class="form-inputs">
          <div class="field mt-3">
            <!-- <label for="email" class="form-label">Email</label> -->
            <label for="email" class="form-label margin">Email</label>
            <span class="p-input-icon-right">
              <i class="getotp-icon cursor-pointer" @click="getOtp" />
              <InputText
                id="email"
                type="email"
                placeholder="Email"
                v-model="vmodel.email"
                 :class="{
                'p-invalid': v$.vmodel.email.$error,
              }"
                class="w-full margin"
              />
            </span>
              <span
              v-if="v$.vmodel.email.$error && v$.vmodel.email.required.$invalid"
              id="email-help"
              class="p-error text-left mt-2 ml-4"
            >
              {{
                v$.vmodel.email.required.$message.replace("Value", "Email")
              }}
            </span>
            <span
              v-else-if="
                v$.vmodel.email.$error && v$.vmodel.email.email.$invalid
              "
              id="email-help"
              class="p-error text-left mt-2 ml-4"
            >
              {{ v$.vmodel.email.email.$message }}
            </span>
            <span
              v-else-if="v$.vmodel.email.$error && v$.vmodel.email.max.$invalid"
              id="email-help"
              class="p-error text-left mt-2 ml-4"
            >
              {{ v$.vmodel.email.max.$message }}
            </span>
            <label for="otp" class="form-label margin mt-2">OTP</label>
            <InputText
              id="otp"
              type="otp"
              placeholder="OTP"
              v-model="vmodel.otp"
              :class="{
                'p-invalid': v$.vmodel.otp.$error,
              }"
              class="w-full margin"
            />
            <span
              v-if="v$.vmodel.otp.$error && v$.vmodel.otp.required.$invalid"
              id="email-help"
              class="p-error text-left mt-2 ml-4"
            >
              {{
                v$.vmodel.otp.required.$message.replace("Value", "OTP")
              }}
            </span>
          </div>
          <div class="field mt-5 mb-2">
            <PrimeButton
              class="prime-button btn-login margin"
              label="CONTINUE"
              @click="submitForm"
            ></PrimeButton>
            <div class="remember">
              Remember it?
              <label class="remember-login ml-2 cursor-pointer"  @click="gotoLogin">Login</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PrimeToast />
  </div>
</template>

<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import { required, email, maxLength } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      id: "",
      vmodel: {
        email: null,
        otp: null,
      },
      submitted: false,
    };
  },
  validations() {
    return {
      vmodel: {
        email: { required, email, max: maxLength(50) },
        otp: {required},
      },
    };
  },
  methods: {
    gotoLogin() {
      this.$router.push({ name: "Login" });
    },
    async getOtp() {
      APIService.get(`/admin/forgotpasswordotp/${this.vmodel.email}`)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.id = response.data.data.id;
            this.$store.commit('setIdForResetPassword', response.data.data.id)
            this.$toast.add({
              severity: "success",
              summary: "Mail Send",
              detail: "Please check your email to proceed further.",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "User Not Found",
              detail:
                "You are not registered. Please contact to administrator.",
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          email: this.vmodel.email,
          otp: this.vmodel.otp,
        };
        this.submitted = true;
        APIService.post("/admin/verifyotp", payload)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              setTimeout(() => {
                this.$router.push({ name: "ResetPassword" });
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      }
    },
  },
};
</script>

<style>
@import "@/assets/css/login.css";
</style>