<template>
  <div class="list-margin">
    <DataTable
      :value="product"
      :paginator="true"
      :lazy="true"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="serialnumber"
      :rowHover="true"
      :resizableColumns="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
    >
      <template #header>
        <div class="flex">
          <div class="col-6 text-left list-header">Product Management</div>
          <div class="col-6 text-right ml-4">
            <PrimeButton
              label="ADD"
              class="add-button mr-3"
              icon="plus-icon"
              @click="gotoCreateProduct"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="Search"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column field="serialnumber" header="SERIAL NO." sortable="true"></Column>
      <Column field="name" header="PRODUCT" sortable="true">
        <template #body="row">
          <div class="flex">
            <img
              :src="getImagePath(row.data.image[0].imagename)"
              alt=""
              class="img-item"
            />
            <span class="margin-username">{{ row.data.name }}</span>
          </div>
        </template>
      </Column>
      <!-- <Column field="name" header="Name" sortable="true"></Column> -->
      <Column
        field="customer"
        header="ASSIGNED CUSTOMER NAME"
        sortable="true"
      ></Column>
      <Column field="createddate" header="CREATED AT" sortable="true"></Column>
      <Column field="actions" header="ACTIONS">
        <template #body="data">
          <div class="flex">
            <a :href="getProductId(data.data.id)" target="_blank" download style="text-decoration: none;">
            <PrimeButton label="QR Code" class="qr-button ml-2" />
            </a>
            <PrimeButton
              label="Edit"
              class="edit-button ml-2"
              @click="gotoProductEdit(data.data.id)"
            />
            <PrimeButton
              label="Delete"
              class="delete-button ml-2"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <div>
      <ConfirmPopup
        :show-popup="showPopup"
        @confirmYes="deleteProduct"
        @confirmNo="showPopup = false"
      ></ConfirmPopup>
    </div>
    <!-- <div>
      <ScanPopup :show-popup="showScanPopup" :product-id="productIdForScan"  @confirmNo="showScanPopup = false">
      </ScanPopup>
    </div> -->
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import ProductFactory from "@/factories/product.js";
export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      memberIdForDelete: "",
      showPopup: false,
      showScanPopup: false,
      productIdForDelete: "",
      productIdForScan: "",
      product: [
        // {
        //   productserialno: "101",
        //   image:"img (PRODUCT NAME)",
        //   productname:"productname",
        //   assignedcustomer:"abcd",
        //  createddate: "09/09/2000",
        // }
      ],
      confirmType: "",
    };
  },
  mounted() {
    this.getProductList();
  },
  methods: {
    gotoCreateProduct() {
      this.$router.push({ name: "ProductCreate" });
    },
    gotoProductEdit(ProductId) {
      this.$router.push({
        name: "ProductEdit",
        params: { id: ProductId },
      });
    },
    getProductId(productId) {
      return `${process.env.VUE_APP_QR_BASE_PATH}${productId}.pdf`
    },
    // showPopupScan(productId) {
    //   this.productIdForScan = productId;
    
    //     this.showScanPopup = true;  
 
    // },
    showDeletePopup(productId) {
      this.productIdForDelete = productId;
      this.showPopup = true;
    },
    getProductList() {
      this.product = [];
      APIService.get(
        `/product/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.search}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalRecords;
          this.product = ProductFactory.createFromJsonArray(response.data.data);
        }
      });
    },
    getImagePath(name) {
      return process.env.VUE_APP_PRODUCT_IMAGE_BASE_PATH + name;
    },
    deleteProduct() {
      APIService.delete(`/product//delete/${this.productIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getProductList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getProductList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createdby") {
        sortField = "createdby";
      } else if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getProductList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getProductList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
.margin-username {
  margin-top: 10px;
  margin-left: 10px;
}
</style>