<template>
  <div class="ml-5 mr-5 margin-top mb-5">
    <div class="grid">
      <div class="flex order-form-title">
        Parts Management<i class="chevron-right-large-icon"></i>{{ title }}
      </div>
      <div class="order-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-3">
              <div class="order-form-sub-title">Parts Detail</div>
            </div>
            <div class="col-9">
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.partno"
                    :validation="v$.vmodel.partno"
                    sectionClass="field mt-1"
                    id="partno"
                    label="Parts No."
                    placeholder="Parts No."
                  />
                </div>
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.type"
                    :validation="v$.vmodel.type"
                    sectionClass="field mt-1"
                    id="type"
                    label="Type"
                    placeholder="Type"
                  />
                </div>
              </div>
              <div class="field">
                <label for="photo" class="form-label">Image</label>
                <FileUpload
                  ref="photoImage"
                  name="photo"
                  :multiple="true"
                  @select="onImageUpload($event)"
                  :aria-describedby="`image-help`"
                >
                  <template #header>
                    <div
                      class="flex align-items-center justify-content-center flex-column"
                    >
                      <span class="upload-icon"></span>
                      <div class="upload-text">
                        Drag and Drop files here or
                        <PrimeButton
                          label="Browse"
                          class="browse-btn cursor-pointer"
                          @click="openImageUpload()"
                        ></PrimeButton>
                      </div>
                    </div>
                  </template>
                  <template #content>
                    <div v-if="imageList.length > 0" class="grid mt-3">
                      <div
                        v-for="(file, index) of imageList"
                        :key="index"
                        class="col-3"
                      >
                        <div class="img-card">
                          <img
                            role="presentation"
                            :alt="file.imagename"
                            :src="file.imagename"
                            class="img-content"
                          />
                          <div class="grid card-content">
                            <span class="col-10 font-semibold img-text">
                              <div class="grid m-0">
                                <span class="col-12 image-name">{{
                                  file.originalname
                                }}</span>
                                <span class="col-12 image-size"
                                  >{{
                                    parseFloat(file.size / 1048576).toFixed(4)
                                  }}
                                  MB</span
                                >
                              </div>
                            </span>
                            <div
                              class="col-2 more-btn"
                              :class="{
                                'image-selected': visibleImageDDMenu == index,
                                '': visibleImageDDMenu !== index,
                              }"
                            >
                              <i
                                class="more-icon cursor-pointer margin-arrow col-2 mt-2"
                                @click="toggleImageMenu($event, index)"
                                aria-haspopup="true"
                                :aria-controls="'overlay_menuimage-' + index"
                              ></i>
                            </div>
                            <Menu
                              class="menustyle"
                              :id="'overlay_menuimage-' + index"
                              :ref="'imageMenu-' + index"
                              :model="setImageMenuItems(file.imagename, index)"
                              :popup="true"
                              @hide="hideImageDD"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </FileUpload>
                <div
                  v-if="
                    v$.vmodel.images.$error &&
                    v$.vmodel.images.required &&
                    v$.vmodel.images.required.$invalid
                  "
                  :id="`image-help`"
                  class="p-error text-left mt-2"
                >
                  {{
                    v$.vmodel.images.required.$message.replace("Value", "Image")
                  }}
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.partdata"
                    :validation="v$.vmodel.partdata"
                    sectionClass="field mt-1"
                    id="partdata"
                    label="Parts Data"
                    placeholder="Parts Data"
                  />
                </div>
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.manufacturing"
                    :validation="v$.vmodel.manufacturing"
                    sectionClass="field mt-1"
                    id="manufacturing"
                    label="Manufacturing Instructions"
                    placeholder="Manufacturing Instructions"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.requirement"
                    :validation="v$.vmodel.requirement"
                    sectionClass="field mt-1"
                    id="requirement"
                    label="Requirements"
                    placeholder="Requirements"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-6" id="date">
                  <InputDate
                    v-model="vmodel.replacedate"
                    :validation="v$.vmodel.replacedate"
                    sectionClass="field mt-1"
                    class="border"
                    :manualInput="false"
                    id="replacedate"
                    label="Parts Replacement Date"
                    placeholder="YYYY-MM-DD"
                    :minDate="new Date()"
                  />
                </div>
                <div class="col-6 mt-1">
                  <div class="grid mb-2">
                    <label for="partservicelife" class="form-label col-12"
                      >Parts service life</label
                    >
                    <span class="p-input-icon-right pl-2">
                      <i class="days-icon" />
                      <InputNumber
                        v-model="vmodel.servicelife"
                        :validation="v$.vmodel.servicelife"
                        sectionClass="field mt-1"
                        id="servicelife"
                        label="Parts service life"
                        placeholder="Parts service life"
                        :min="2"
                      />
                    </span>
                  </div>
                  <span
                    v-if="
                      v$.vmodel.servicelife?.$error &&
                      v$.vmodel.servicelife.required &&
                      v$.vmodel.servicelife.required.$invalid
                    "
                    :id="`servicelife-help`"
                    class="p-error text-left"
                  >
                    {{
                      v$.vmodel.servicelife.required.$message.replace(
                        "Value",
                        "Parts service life"
                      )
                    }}
                  </span>
                  <span
                    v-else-if="
                      v$.vmodel.servicelife.$error &&
                      v$.vmodel.servicelife.min &&
                      v$.vmodel.servicelife.min.$invalid
                    "
                    :id="`partservicelife-help`"
                    class="p-error text-left"
                  >
                    {{ v$.vmodel.servicelife.min.$message }}
                  </span>
                  <span
                    v-else-if="
                      v$.vmodel.servicelife.$error &&
                      v$.vmodel.servicelife.max &&
                      v$.vmodel.servicelife.max.$invalid
                    "
                    :id="`partservicelife-help`"
                    class="p-error text-left"
                  >
                    {{ v$.vmodel.servicelife.max.$message }}
                  </span>
                  <span
                    v-else-if="
                      v$.vmodel.servicelife?.$error &&
                      v$.vmodel.servicelife.numeric &&
                      v$.vmodel.servicelife.numeric.$invalid
                    "
                    :id="`partservicelife-help`"
                    class="p-error text-left"
                  >
                    {{ v$.vmodel.servicelife.numeric.$message }}
                  </span>
                </div>
              </div>
              <div class="field">
                <label for="note" class="form-label">Note & Documents</label>
                <FileUpload
                  ref="noteDocs"
                  name="note"
                  accept=".pdf"
                  :multiple="true"
                  @select="onNoteUpload($event)"
                  :aria-describedby="`note-help`"
                >
                  <template #header>
                    <div
                      class="flex align-items-center justify-content-center flex-column"
                    >
                      <span class="upload-icon"></span>
                      <div class="upload-text">
                        Drag and Drop files here or
                        <PrimeButton
                          label="Browse"
                          class="browse-btn cursor-pointer"
                          @click="openDocsUpload()"
                        ></PrimeButton>
                      </div>
                    </div>
                  </template>
                  <template #content>
                    <div v-if="noteList.length > 0" class="grid">
                      <div
                        v-for="(file, index) of noteList"
                        :key="index"
                        class="col-3"
                      >
                        <div class="pdf-card">
                          <div class="grid card-content">
                            <span class="col-10 font-semibold pdf-text">
                              <div class="grid m-0">
                                <span class="col-12 pdf-name">{{
                                  file.originalname
                                }}</span>
                                <span class="col-12 pdf-size"
                                  >{{
                                    parseFloat(file.size / 1048576).toFixed(4)
                                  }}
                                  MB</span
                                >
                              </div>
                            </span>
                            <div
                              class="col-2 pdf-more-btn"
                              :class="{
                                'image-selected': visibleNoteDDMenu == index,
                                '': visibleNoteDDMenu !== index,
                              }"
                            >
                              <i
                                class="more-icon cursor-pointer margin-arrow col-2 mt-2"
                                @click="toggleNoteMenu($event, index)"
                                aria-haspopup="true"
                                :aria-controls="'overlay_menunote-' + index"
                              ></i>
                            </div>
                            <Menu
                              class="menustyle"
                              :id="'overlay_menunote-' + index"
                              :ref="'noteMenu-' + index"
                              :model="
                                setNoteMenuItems(file.documentname, index)
                              "
                              :popup="true"
                              @hide="hideNoteDD"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </FileUpload>
                <div
                  v-if="
                    v$.vmodel.note.$error &&
                    v$.vmodel.note.required &&
                    v$.vmodel.note.required.$invalid
                  "
                  :id="`note-help`"
                  class="p-error text-left mt-2"
                >
                  {{
                    v$.vmodel.note.required.$message.replace(
                      "Value",
                      "Note & Documents"
                    )
                  }}
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <DropDownRequire
                    v-model="vmodel.productid"
                    :validation="v$.vmodel.productid"
                    :options="productOptions"
                    sectionClass="field mt-1"
                    id="productid"
                    label="Assign Product "
                    placeholder="Assign Product"
                    optionLabel="name"
                    optionValue="id"
                  />
                </div>
              </div>

              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="order-form-button"
                  v-if="!isEditMode"
                  @click="createPart"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="order-form-button"
                  v-else
                  @click="createPart"
                ></PrimeButton>
                <PrimeButton
                  label="Cancel"
                  class="form-cancel-button"
                  @click="onCancel"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div>
  </div> -->
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import DateFormat from "@/libs/DateFormat";
import APIService from "@/services/api-service.js";
import {
  required,
  maxLength,
  minLength,
  maxValue,
  minValue,
  numeric,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      visibleImageDDMenu: -1,
      visibleNoteDDMenu: -1,
      photo: "",
      note: "",
      imageList: [],
      imgList: [],
      noteDocsList: [],
      noteList: [],
      submitted: false,
      isEditMode: false,
      productOptions: [],
      errorcatchImage: 0,
      errorcatchNote: 0,
      imageFileTypes: ["png", "jpg", "jpeg"],
      docsFileTypes: ["application/pdf"],
      title: "Add",
      minDate: null,
      vmodel: {
        partno: "",
        type: "",
        images: [],
        partdata: "",
        manufacturing: "",
        requirement: "",
        replacedate: "",
        servicelife: 1,
        note: [],
        productid: "",
      },
    };
  },
  validations() {
    return {
      vmodel: {
        partno: {
          required,
          numeric,
          max: maxLength(9),
          min: minLength(9),
        },
        type: { required, max: maxLength(50) },
        images: { required },
        partdata: { required, max: maxLength(50) },
        manufacturing: { required, max: maxLength(50) },
        requirement: { required, max: maxLength(50) },
        replacedate: { required },
        servicelife: {
          required,
          numeric,
          min: minValue(1),
          max: maxValue(999),
        },
        note: { required },
        productid: { required },
      },
    };
  },
  mounted() {
    this.getProduct();
    if (this.$route.params.id) {
      this.title = "Edit";
      this.isEditMode = true;
      this.getPartsById();
    }
  },
  methods: {
    hideNoteDD() {
      this.visibleNoteDDMenu = -1;
    },
    hideImageDD() {
      this.visibleImageDDMenu = -1;
    },
    toggleImageMenu(event, index) {
      this.visibleImageDDMenu = index;
      this.$refs[`imageMenu-${index}`][0].toggle(event);
    },
    toggleNoteMenu(event, index) {
      this.visibleNoteDDMenu = index;
      this.$refs[`noteMenu-${index}`][0].toggle(event);
    },
    setImageMenuItems(file, index) {
      let menu = [];
      if (file) {
        menu = [
          {
            label: "Delete",
            command: () => {
              this.onRemoveImageFile(index);
            },
          },
        ];
      }
      return menu;
    },
    setNoteMenuItems(file, index) {
      let menu = [];
      if (file) {
        menu = [
          {
            label: "View",
            command: () => {
              this.openPDFFile(file);
            },
          },
          {
            label: "Delete",
            command: () => {
              this.onRemoveNoteFile(index);
            },
          },
        ];
      }
      return menu;
    },
    openImageUpload() {
      this.$refs.photoImage.choose();
    },
    openDocsUpload() {
      this.$refs.noteDocs.choose();
    },
    onCancel() {
      this.$router.push({ name: "Parts" });
    },
    onRemoveImageFile(index) {
      this.imgList.splice(index, 1);
      this.imageList.splice(index, 1);
      this.visibleImageDDMenu = -1;
    },
    onRemoveNoteFile(index) {
      this.noteDocsList.splice(index, 1);
      this.noteList.splice(index, 1);
      this.visibleNoteDDMenu = -1;
    },
    openPDFFile(file) {
      window.open(file);
    },
    onImageUpload(fileObj) {
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid image",
          life: 3000,
        });
        this.$refs.photoImage.clear();
      } else if (fileObj && fileObj.files) {
        for (let file of fileObj.files) {
          if (file.size > 2000000) {
            this.errorcatchImage++;
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Image size should be less then 2 MB",
              life: 3000,
            });
            this.$refs.photoImage.clear();
          }

          let ext = /^.+\.([^.]+)$/.exec(file.name);
          if (!this.imageFileTypes.includes(ext[1].toLowerCase())) {
            this.errorcatchImage++;
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Image Type must be .jpg, .png or .jpeg",
              life: 3000,
            });
            this.$refs.photoImage.clear();
          }
        }
      }

      if (this.errorcatchImage == 0) {
        let formData = new FormData();
        for (let file of fileObj.files) {
          formData.append("image", file);
        }
        APIService.post("/parts/parts", formData, null, true)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              for (let file of response.data.data) {
                const uploadImgList = {
                  imagename:
                    process.env.VUE_APP_PART_IMAGE_BASE_PATH + file.imagename,
                  originalname: file.originalname,
                  size: file.size,
                };
                const uploadedImg = {
                  imagename: file.imagename,
                  originalname: file.originalname,
                  size: file.size,
                };
                this.imgList.push(uploadedImg);
                this.imageList.push(uploadImgList);
              }
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$refs.photoImage.clear();
          });
      }
      this.errorcatchImage = 0;
    },
    onNoteUpload(fileObj) {
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid Note & Documents",
          life: 3000,
        });
        this.$refs.noteDocs.clear();
      } else if (fileObj && fileObj.files) {
        for (let file of fileObj.files) {
          if (file.size > 2000000) {
            this.errorcatchNote++;
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Note & Documents size should be less then 2 MB",
              life: 3000,
            });
            this.$refs.noteDocs.clear();
          }

          if (!this.docsFileTypes.includes(file.type)) {
            this.errorcatchNote++;
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Note & Documents Type must be .pdf",
              life: 3000,
            });
            this.$refs.noteDocs.clear();
          }
        }
      }

      if (this.errorcatchNote == 0) {
        let formData = new FormData();
        for (let file of fileObj.files) {
          formData.append("file", file);
        }
        APIService.post("/parts/pdf/contentfile", formData, null, true)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              for (let file of response.data.data) {
                const uploadNoteList = {
                  documentname:
                    process.env.VUE_APP_CONTENT_FILE_BASE_PATH +
                    file.documentname,
                  originalname: file.originalname,
                  size: file.size,
                };
                const uploadedNote = {
                  documentname: file.documentname,
                  originalname: file.originalname,
                  size: file.size,
                };
                this.noteDocsList.push(uploadedNote);
                this.noteList.push(uploadNoteList);
              }
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$refs.noteDocs.clear();
          });
      }
      this.errorcatchNote = 0;
    },
    async createPart() {
      this.vmodel.images = this.imgList;
      this.vmodel.note = this.noteDocsList;
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          partsno: this.vmodel.partno,
          type: this.vmodel.type,
          image: this.vmodel.images,
          partsdata: this.vmodel.partdata,
          manufacturing: this.vmodel.manufacturing,
          requirement: this.vmodel.requirement,
          replacedate: this.vmodel.replacedate,
          servicelife: this.vmodel.servicelife,
          notedocuments: this.vmodel.note,
          productid: this.vmodel.productid,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/parts", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Parts" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/parts", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Parts" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getPartsById() {
      APIService.getById("/parts", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.partno = response.data.data.partsno;
            this.vmodel.type = response.data.data.type;
            this.vmodel.images = response.data.data.image;
            this.vmodel.partdata = response.data.data.partsdata;
            this.vmodel.manufacturing = response.data.data.manufacturing;
            this.vmodel.requirement = response.data.data.requirement;
            this.vmodel.replacedate = DateFormat.formatDate(
              response.data.data.replacedate
            );
            this.vmodel.servicelife = response.data.data.servicelife;
            this.vmodel.note = response.data.data.notedocuments;
            this.vmodel.productid = response.data.data.productid;
            if (
              response.data.data.image &&
              response.data.data.image.length > 0
            ) {
              response.data.data.image.forEach((element) => {
                const uploadImgList = {
                  imagename:
                    process.env.VUE_APP_PART_IMAGE_BASE_PATH +
                    element.imagename,
                  originalname: element.originalname,
                  size: element.size,
                };
                const uploadedImg = {
                  imagename: element.imagename,
                  originalname: element.originalname,
                  size: element.size,
                };
                this.imageList.push(uploadImgList);
                this.imgList.push(uploadedImg);
              });
            }
            if (
              response.data.data.notedocuments &&
              response.data.data.notedocuments.length > 0
            ) {
              response.data.data.notedocuments.forEach((element) => {
                const uploadNoteList = {
                  documentname:
                    process.env.VUE_APP_CONTENT_FILE_BASE_PATH +
                    element.documentname,
                  originalname: element.originalname,
                  size: element.size,
                };
                const uploadedNote = {
                  documentname: element.documentname,
                  originalname: element.originalname,
                  size: element.size,
                };
                this.noteList.push(uploadNoteList);
                this.noteDocsList.push(uploadedNote);
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Parts" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    async getProduct() {
      APIService.get("/product/dropdown").then((response) => {
        if (response && response.data && response.data.data) {
          this.productOptions = response.data.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/upload-image.css";
::v-deep(.border) {
  .p-inputtext {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    border-right: none;
  }
}
::v-deep(#date) {
  .p-invalid .p-button {
    border-color: #dc3545;
  }
  .p-button {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background: #f4f5f6;
    border: 1px solid #d4d7d8;
    border-width: 1px 1px 1px 0;
  }

  .p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
