<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container-top">
    <span class="navbar-text">Gold Noble International Limited</span>
    <div class="flex align-items-center">
      <span class="navbar-profile-text text-right">
        {{ $store.state.userInfo.username }}
      </span>
      <i
        v-click-outside="hide"
        class="arrow-icon arrow-style cursor-pointer mt-3"
        @click="visibleDDMenu = !visibleDDMenu"
      ></i>
    </div>
  </div>
  <div v-if="visibleDDMenu" class="dropdown">
    <div class="grid " >
      <div 
        style="margin-top: 17px"
        class="col-12 ml-5 profile-setting-text cursor-pointer"
        @click="goToProfilePage"
      >
        Profile Setting
      </div>
    </div>
    <div class="grid">
      <hr class="line" />
    </div>
    <div class="grid">
      <div
        style="margin-top: 6px"
        class="col-12 ml-5 profile-setting-text cursor-pointer"
        @click="logout"
      >
        Logout
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visibleDDMenu: false,
    };
  },
  methods: {
    hide(){
      this.visibleDDMenu = false;
    },
    goToProfilePage() {
      this.$router.push({ name: "ProfileSetting" });
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped>
.arrow-style {
  margin-top: 10px;
  margin-left: 10px;
}

.profile-role-style {
  margin-top: -16px;
  text-align: right;
}

.margin-arrow {
  /* margin-right: -54px;
  margin-top: 16px; */
  margin-left: -59px;
  margin-top: -57px;
}

.margin-img {
  /* margin-right: -10px;
  margin-top: 7px; */
  margin-left: -107px;
  margin-top: 34px;
}

.img-style {
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  filter: drop-shadow(0px 2px 8px rgba(187, 187, 187, 0.36));
}

.dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px;
  position: absolute;
  width: 160px;
  height: 104px;
  right: 3.5rem;
  top: 100px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02), 0px 10px 8px rgba(0, 0, 0, 0.03), 0px 0px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  /* margin-left: -376px; */
  z-index: 9999;
}

.profile-setting-text {

  font-family: OpenSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
.ml-5 {
  margin-left: 1.3rem !important;
}

.line {
  margin-left: 7px;
  width: 160px;
  border-bottom: 1px solid rgba(27, 27, 27, 0.15);
  /* margin-top: 24px; */
}

.profile {
  margin-top: 18px;
  margin-left: 6px;
}

.logout {
  margin-top: 10px;
  margin-left: 6px;
}
</style>