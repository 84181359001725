<template>
  <Dialog
    v-model:visible="display"
    header="Product QR"
    closeIcon="pi pi-times"
  >
   
    
    <div>
      <span><img :src="getProductId" alt="" class="img-style" /></span>
    </div>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      display: false,
    };
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
      required: true,
    },
    productId: {
      type: String,
    }
  },
  computed: {
    getProductId() {
      return `${process.env.VUE_APP_QR_BASE_PATH}${this.productId}.png`
    }
  },
  watch: {
    showPopup: {
      handler(newValue) {
        if (newValue === true) {
          this.openPopup();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    openPopup() {
      this.display = true;
    },
  },
};
</script>

<style scoped>
.img-style {
  /* margin-top: 20px;
  margin-left: 30px; */
  margin:auto;
  height: 250px;
  width: 250px;
  
}
</style>