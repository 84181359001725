<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="main-container">
    <div class="login-form form-container">
      <div class="login-logo">
        <img src="../assets/icons/logo.png" />
      </div>
      <div class="form-title margin mt-3">Login</div>
      <div class="form-inputs">
        <div class="field mt-3">
          <label for="email" class="form-label margin">Username</label>
          <InputText
            id="email"
            type="email"
            placeholder="Username"
            v-model="vmodel.email"
            :class="{
              'p-invalid': v$.vmodel.email.$error,
            }"
            class="w-full margin"
            maxLength="50"
          />
          <span
            v-if="v$.vmodel.email.$error && v$.vmodel.email.required.$invalid"
            id="email-help"
            class="p-error text-left mt-2 ml-4"
          >
            {{ v$.vmodel.email.required.$message.replace("Value", "Username") }}
          </span>
          <span
            v-else-if="v$.vmodel.email.$error && v$.vmodel.email.email.$invalid"
            id="email-help"
            class="p-error text-left mt-2 ml-4"
          >
            {{ v$.vmodel.email.email.$message }}
          </span>
          <span
            v-else-if="v$.vmodel.email.$error && v$.vmodel.email.max.$invalid"
            id="email-help"
            class="p-error text-left mt-2 ml-4"
          >
            {{ v$.vmodel.email.max.$message }}
          </span>
        </div>
        <div class="field mt-1">
          <div class="flex-row">
            <label for="password" class="form-label margin float-left"
              >Password</label
            >
            <label
              for="password"
              class="form-label-forgot-password cursor-pointer mb-0"
              @click="gotoForgotPassword"
              >Forgot Password?</label
            >
          </div>
          <span class="p-input-icon-right">
            <i
              :class="
                showPassword
                  ? 'password-hide-icon cursor-pointer'
                  : 'eye-icon cursor-pointer'
              "
              @click="showPassword = !showPassword"
            />
            <InputText
              id="password"
              placeholder="Password"
              :type="passwordInputType"
              class="w-full margin"
              v-model="vmodel.password"
              :class="{
              'p-invalid': v$.vmodel.password.$error,
              }"
              aria-describedby="password-help"
            />
          </span>

          <span
            v-if="
              v$.vmodel.password.$error && v$.vmodel.password.required.$invalid
            "
            id="password-help"
            class="p-error ml-4 mt-2"
          >
            {{
              v$.vmodel.password.required.$message.replace("Value", "Password")
            }}
          </span>
          <span
            v-else-if="
              v$.vmodel.password.$error && v$.vmodel.password.min.$invalid
            "
            id="password-help"
            class="p-error ml-4 mt-2"
          >
            {{ v$.vmodel.password.min.$message }}
          </span>
          <span
            v-else-if="
              v$.vmodel.password.$error && v$.vmodel.password.max.$invalid
            "
            id="password-help"
            class="p-error ml-4 mt-2"
          >
            {{ v$.vmodel.password.max.$message }}
          </span>
        </div>
        <div class="field mt-5 mb-2">
          <PrimeButton
            label="Login"
            class="btn-login margin"
            @click="login"
          ></PrimeButton>
        </div>
      </div>
    </div>
    <PrimeToast />
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import APIService from "@/services/api-service.js";
import { required, email, minLength, maxLength } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      showPassword: false,
      vmodel: {
        email: null,
        password: null,
      },
      submitted: false,
    };
  },
  validations() {
    return {
      vmodel: {
        email: { required, email, max: maxLength(50) },
        password: { required, min: minLength(8), max: maxLength(20) },
      },
    };
  },
  computed: {
    passwordInputType() {
      if (this.showPassword) {
        return "text";
      }
      return "password";
    },
  },
  methods: {
    gotoForgotPassword() {
      this.$router.push({ name: "ForgotPassword" });
    },
    async login() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          email: this.vmodel.email,
          password: this.vmodel.password,
        };
        this.submitted = true;
        APIService.post("/admin/login", payload)
          .then((response) => {
            if (response && response.data && response.data.data) {
              this.$store.commit("setUserInfo", response.data.data);
              this.$store.commit("setUserToken", response.data.token);
              this.$store.dispatch("login");
              this.$router.push({ name: "Customer" });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      }
    },
  },
};
</script>

<style>
@import "@/assets/css/login.css";

/* .img-style {
  margin-top: -62px;
} */
</style>
