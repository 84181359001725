import DateFormat from "@/libs/DateFormat"

export default class Parts {
    constructor(data = {}) {
        return {
            id: data.id || '',
            partsno: data.partsno || '',
            type: data.type|| '',
            partsdata: data.partsdata || '',
            installationdate: DateFormat.formatDateTime(data.installationdate) || '',
            expirationdate: DateFormat.formatDateTime(data.expirationdate) || '',
        }
    }
}