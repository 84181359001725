import DateFormat from "@/libs/DateFormat"

export default class Product {
    constructor(data = {}) {
        return {
            id: data.id || '',
            serialnumber: data.serialnumber || '',
            image: data.image || [],
            name: data.name || '',
            description: data.description || '',
            customer: data.customer || '',
            createddate: DateFormat.formatDateTime(data.createddate) || '',
        }
    }
}