import { createWebHistory, createRouter } from "vue-router";
import Store from '../store';
import MainLayout from "@/views/main-layout.vue";

import Login from '@/views/login.vue'
import ForgotPassword from '@/views/forgot-password.vue'
import ResetPassword from '@/views/reset-password.vue'
import Customer from '@/views/Customer/customer-list.vue'
import CustomerCreate from '@/views/Customer/customer-form.vue'
import Product from '@/views/Product/product-list.vue'
import ProductCreate from '@/views/Product/product-form.vue'
import Parts from '@/views/Parts/parts-list.vue'
import PartsCreate from '@/views/Parts/parts-form.vue'
import PrivacyPolicy from "@/views/PrivacyPolicyPage/privacy-policy.vue"
import TermsAndConditions from "@/views/TermsAndConditionsPage/terms-and-conditions.vue"
import ProfileSetting from "@/views/ProfileSetting/profile-setting.vue"

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            title: "Login"
        }
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
        meta: {
            title: "Forgot password"
        }
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        component: ResetPassword,
        meta: {
            title: "Reset Password"
        }
    },
    {
        component: MainLayout,
        children: [
            {
                path: "/customer",
                name: "Customer",
                component: Customer,
                meta: {
                    requiresAuth: true,
                    module: 'Customer',
                    title: "Customer"
                }
            },
            {
                path: "/customer/create",
                name: "CustomerCreate",
                component: CustomerCreate,
                meta: {
                    requiresAuth: true,
                    module: 'Customer',
                    title: "Customer Management > Add"
                }
            },
            {
                path: "/customer/edit/:id",
                name: "CustomerEdit",
                component: CustomerCreate,
                meta: {
                    requiresAuth: true,
                    module: 'Customer',
                    title: "Customer Management > Edit",
                },
            },
            {
                path: "/product",
                name: "Product",
                component: Product,
                meta: {
                    requiresAuth: true,
                    module: 'Product',
                    title: "Product"
                }
            },
            {
                path: "/product/create",
                name: "ProductCreate",
                component: ProductCreate,
                meta: {
                    requiresAuth: true,
                    module: 'Product',
                    title: "Product Management > Add"
                }
            },
            {
                path: "/product/edit/:id",
                name: "ProductEdit",
                component: ProductCreate,
                meta: {
                    requiresAuth: true,
                    module: 'Product',
                    title: "Product Management > Edit",
                },
            },
            {
                path: "/parts",
                name: "Parts",
                component: Parts,
                meta: {
                    requiresAuth: true,
                    module: 'Parts',
                    title: "ProductLPartsifeCycle"
                }
            },
            {
                path: "/parts/create",
                name: "PartsCreate",
                component: PartsCreate,
                meta: {
                    requiresAuth: true,
                    module: 'Parts',
                    title: "Parts Create Management > Add"
                }
            },
            {
                path: "/parts/edit/:id",
                name: "PartsEdit",
                component: PartsCreate,
                meta: {
                    requiresAuth: true,
                    module: 'Parts',
                    title: "Parts Management > Edit",
                },
            },
            {
                path: "/privacy-policy",
                name: "PrivacyPolicy",
                component: PrivacyPolicy,
                meta: {
                    requiresAuth: true,
                    module: 'PrivacyPolicy',
                    title: "Privacy policy"
                },
            },
            {
                path: "/terms-conditions",
                name: "TermsAndConditions",
                component: TermsAndConditions,
                meta: {
                    requiresAuth: true,
                    module: 'TermsAndConditions',
                    title: "Terms and conditions"
                },
            }, 
            {
                path: "/profile-setting",
                name: "ProfileSetting",
                component: ProfileSetting,
                meta: {
                    requiresAuth: true,
                    module: 'ProfileSetting',
                    title: "Profile Setting",
                },
            },





        ]
    }

]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const title = 'PDMS-CAR APP';
    if (Store.state.timeout == null || (Store.state.timeout != null && Store.state.timeout > new Date())) {
        if (to.matched.some(record => record.meta.requiresAuth)) {
            if (Store.state.hasLoggedIn) {
                Store.commit('setUserTimeout');
                Store.commit('setActiveRoute', to.meta.module);
                window.document.title = to.meta && to.meta.title ? title + ' - ' + to.meta.title : title;
                next()
                return
            }
            Store.dispatch("logout");
            next('/login')
            return
        } else if (to.name === undefined) {
            if (Store.state.hasLoggedIn) {
                Store.commit('setActiveRoute', to.meta.module);
                window.document.title = to.meta && to.meta.title ? title + ' - ' + to.meta.title : title;
                next()
                return
            }
            Store.dispatch("logout");
            next('/login')
            return
        } else {
            Store.commit('setUserTimeout');
            if (to.name == "Login" && Store.state.hasLoggedIn) {
                Store.commit('setActiveRoute', 'Customer');
                next("/customer");
                return
            } else {
                window.document.title = to.meta && to.meta.title ? title + ' - ' + to.meta.title : title;
                next()
                return
            }            
        }
    } else {        
        Store.dispatch("logout");
        next("/login");
        return
    }
})

export default router;