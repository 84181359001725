<template>
  <div class="sidebar">
    <div class="logo">
      <span><img src="../../assets/icons/logo.png" /></span>
    </div>
    <div class="menu mt-6">
      <router-link
        :to="{ name: 'Customer' }"
        id="Customer"
        :class="
          $store.state.activeRoute === 'Customer'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
      <i :class=" $store.state.activeRoute === 'Customer'
            ? 'white-customer-icon selected'
            : 'customer-icon'
        "></i>
        <span class="menu-text">Customer Management</span>
      </router-link>
      <router-link
        :to="{ name: 'Product' }"
        id="Product"
        :class="
          $store.state.activeRoute === 'Product'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
         <i :class=" $store.state.activeRoute === 'Product'
            ? 'white-product-icon selected'
            : 'product-icon'
        "></i>
        <span class="menu-text">Product Management</span>
      </router-link>
        <router-link
        :to="{ name: 'Parts' }"
        id="Parts"
        :class="
          $store.state.activeRoute === 'Parts'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
         <i :class=" $store.state.activeRoute === 'Parts'
            ? 'white-parts-icon selected'
            : 'parts-icon'
        "></i>
        <span class="menu-text">Parts Management</span>
      </router-link>
      <div
        class="menu-item cursor-pointer"
        @click="visibleCMSMenu = !visibleCMSMenu"
      >
        <i class="cms-icon"></i>
        <span class="menu-text">CMS Page Management</span>
        <i
          class="margin"
          :class="{
            ' chevron-down-icon': !visibleCMSMenu,
            ' chevron-up-icon': visibleCMSMenu,
          }"
        ></i>
      </div>
      <router-link
        v-if="visibleCMSMenu"
        :to="{ name: 'PrivacyPolicy' }"
        id="PrivacyPolicy"
        :class="
          $store.state.activeRoute === 'PrivacyPolicy'
            ? 'menu-item selected'
            : 'menu-item'
        "
        class="dropdown-list-padding"
      >
        <i :class=" $store.state.activeRoute === 'PrivacyPolicy'
            ? 'white-page-both selected'
            : 'page-both'
        "></i>

        <span class="menu-text">Privacy Policy </span>
      </router-link>

      <router-link
        v-if="visibleCMSMenu"
        :to="{ name: 'TermsAndConditions' }"
        id="TermsAndConditions"
        :class="
          $store.state.activeRoute === 'TermsAndConditions'
            ? 'menu-item selected'
            : 'menu-item'
        "
        class="dropdown-list-padding"
      >
        <i :class=" $store.state.activeRoute === 'TermsAndConditions'
            ? 'white-page-both selected'
            : 'page-both'
        "></i>
        <span class="menu-text">Terms & Conditions </span>
      </router-link>



       
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarMenu",
  data() {
    return {
      // username: "chantaiman12345",
      visibleLeft: true,
      visibleCMSMenu: false,
      visibleDDMenu: false,

    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/sidebar.css";

.dropdown-list-padding {
  padding-left: 57px;
}

.margin {
  margin-left: 38px !important;
  margin-top: 17px;
}
</style>